import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _readline2 from "readline";

var _readline = "default" in _readline2 ? _readline2.default : _readline2;

var exports = {};
const fs = _fs;
const readline = _readline;

class Onkun {
  static async load(filepath) {
    const dict = {};

    if (!filepath) {
      filepath = new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname + "/Unihan-kJapaneseOnKun.txt";
    }

    const fileReader = fs.createReadStream(filepath);
    const rl = readline.createInterface({
      input: fileReader
    });

    for await (const line of rl) {
      let [kanji, on, kun] = line.split("\t");
      if (on) on = on.split(" ");
      if (kun) kun = kun.split(" ");
      dict[kanji] = [on, kun];
    }

    const onkun = new Onkun();
    onkun.dict = dict;
    return onkun;
  }

  constructor() {
    this.dict = {};
  }

  get(word) {
    return this.dict[word];
  }

}

exports = Onkun;
export default exports;